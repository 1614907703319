<template>
  <div class="backorders-import">
    <div class="dropzone bg-white" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <input type="file" name="fields[assetsFieldHandle_bo][]" id="assetsFieldHandle_bo" @change="onChange" ref="file" style="opacity: 0;display:none"/>
      <div v-if="!filelist.length" style="padding-top:10px;padding-left:25px;color:gray;display:flex;align-items:center;">
        <v-icon class="mdi mdi-file-upload-outline orange--text"></v-icon>
        <span style="padding-left:10px;">Backorders File Drop</span>
      </div>
    </div>
    <div style="color:red;font-size:10px;font-weight:bold;padding-top:5px">Customers should be updated before Backorders</div>
    <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;position:relative;">
      <v-btn style="background:#ff7214;color:white;height:15px;width:75px;font-size:8px;margin-right:10px;" @click="pullData()">PULL DATA</v-btn>
      <div>
        <div style="font-size:12px;color:gray;font-style:italic;">Last Imported File Date: {{lastUploadDate}}</div>
        <div style="font-size:12px;color:gray;font-style:italic;">Last Pull Data Date: {{lastPullDate}}</div>
      </div>
    </div>

  </div>
</template>

<script>

// import * as XLSX from "xlsx"
import Papa from "papaparse"
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import AWS from 'aws-sdk';

export default {
  name: 'BackordersImport',
  components: {Vue3EasyDataTable},
  data(){
    return {
      filelist: [],
      searchValue: '',
      sortBy: 'customer_name',
      sortType: 'asc',
    }
  },
  mounted() {

  },
  watch: {
    filelist(val){
      if(val.length > 0){
        this.readFiles()
      }
    }
  },
  computed: {
    ...mapGetters(['user','db']),
    lastUploadDate(){
      return Moment(this.db.last_backorder_file_upload).format('MM/DD/YY hh:mma')
    },
    lastPullDate(){
      return this.db.last_backorder_file_pull ? Moment(this.db.last_backorder_file_pull).format('MM/DD/YY hh:mma') : false
    }
  },
  methods: {
    async pullData(){
      AWS.config.region = 'us-east-1';

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-east-1:44b121e3-3117-4e3a-b0b1-a9ea0abf7772',
      });

      AWS.config.credentials.get(() => {
        const s3 = new AWS.S3();

        const params = {
          Bucket: 'cloudmailing-files',
          Prefix: 'backorders/'
        };

        s3.listObjectsV2(params, (err, data) => {
          if (err) {
            console.error(err);
          } else {
            const files_arr = data['Contents'].sort((a, b) => (a.LastModified < b.LastModified) ? 1 : ((b.LastModified < a.LastModified) ? -1 : 0));
            const fileKey = files_arr[0].Key;
            const s3Params = { Bucket: 'cloudmailing-files', Key: fileKey };

            var most_recent_file_date = Moment(new Date(files_arr[0].LastModified));
            var today_date = Moment(new Date());
            var is_same_day = most_recent_file_date.isSame(today_date, 'day');
            var shouldProceed = is_same_day;
            var file_date = Moment(files_arr[0].LastModified, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').valueOf();

            if (!is_same_day) {
                var msg = 'This file is from ' + most_recent_file_date.format('MM-DD-YYYY') + '. Today\'s file has not loaded to the cloud yet. Do you want to proceed with loading the older file?'
                shouldProceed = confirm(msg);
            }

            if (shouldProceed) {
              s3.getObject(s3Params, (err, data) => {
                if (err) {
                  console.error(err);
                } else {
                  const file = new File([data.Body], fileKey);
                  this.writeInitialData(file,file_date);
                }
              });
            }

          }
        });
      });
    },
    searchFiles(){
      document.getElementById("assetsFieldHandle_bo").click()
    },
    onChange() {
      var files_arr = [...this.$refs.file.files];
      files_arr = _.sortBy(files_arr, (obj) => parseInt(obj.name, 10));
      this.filelist = files_arr;
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-orange')) {
        event.currentTarget.classList.remove('bg-white');
        event.currentTarget.classList.add('bg-orange');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-white');
      event.currentTarget.classList.remove('bg-orange');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      event.currentTarget.classList.add('bg-white');
      event.currentTarget.classList.remove('bg-orange');
      this.onChange();
    },
    readFiles(){
      for(var i = 0; i < this.filelist.length; i++){
        var file_name = this.filelist[i].name.toLowerCase()
        if(this.filelist[i].type.indexOf('text/csv') !== -1 && file_name.indexOf('backorder') !== -1){
          this.writeInitialData(this.filelist[i], Date.now())
        }else{
          this.$store.state.isLoading = false
          this.remove(this.filelist[i])
          alert('Invalid File(s)')
        }
      }
    },
    writeInitialData(file,file_date){
      var reader = new FileReader();
      var self = this
      self.$store.state.isLoading = true
      reader.readAsBinaryString(file);

      reader.onload = function(e) {
        var data = e.target.result;
        var parsedData = Papa.parse(data, {
          header: true, // Set header to true to parse the first row as headers
          dynamicTyping: false, // Disable automatic type casting
          skipEmptyLines: true, // Skip empty lines
        });

        var json = parsedData.data;

        if (json.length === 0) {
          self.$store.state.isLoading = false;
          alert('Invalid File(s)');
          return;
        }

        setTimeout(function(){
          self.updateDatabase(json,file_date)
        },1000)
      }
    },
    updateDatabase(json,file_date){
      var self = this
      var current_db_backorders_clone = self.db.backorders ? JSON.parse(JSON.stringify(self.db.backorders)) : {}
      var current_sales_orders_arr = self.db.sales_orders ? JSON.parse(JSON.stringify(Object.keys(self.db.sales_orders))) : []
      var new_upload_sales_orders_arr = []
      var upload_date = Date.now()
      var new_backorders_file_object = {}
      var updates_for_db = {};
      var new_file_backorder_keys = []

      for(var i = 0; i < json.length; i++){
        var row_data = json[i]
        var file_backorder_line = {
            'backorder_key': row_data['Order Number'].replace(/[^0-9a-z]/gi, '') + '-' + row_data['Line Number'] + '-' + row_data['Product Number'].replace(/[^0-9a-z]/gi, ''),
            'customer_name': row_data['Customer Name'] ? row_data['Customer Name'] : '',
            'customer_number': row_data['Customer Number'] ? String(parseFloat(row_data['Customer Number'])) : '',
            'customer_po': row_data['Customer PO'] ? row_data['Customer PO'] : '',
            'order_date': row_data['Order Date'] ? Moment(row_data['Order Date'], 'MM-DD-YYYY').format('M/DD/YY') : '',
            'ordered_by_name': row_data['Ordered By Name'] ? row_data['Ordered By Name'] : '',
            'order_number': row_data['Order Number'] ? row_data['Order Number'] : '',
            'line_number': row_data['Line Number'] ? row_data['Line Number'] : '',
            'product_number': row_data['Product Number'] ? row_data['Product Number'] : '',
            'description_line_one': row_data['Description Line One'] ? row_data['Description Line One'] : '',
            'quantity_ordered': row_data['Quantity Ordered'] ? row_data['Quantity Ordered'] : '',
            'quantity_to_pick': row_data['Quantity to Pick'] ? row_data['Quantity to Pick'] : '',            
            'quantity_backordered': row_data['Quantity Backordered'] ? row_data['Quantity Backordered'] : '',            
            'unit_of_measure': row_data['Unit of Measure'] ? row_data['Unit of Measure'] : '',
            'ship_to_info_flat': row_data['Ship To Info Flat'] ? row_data['Ship To Info Flat'] : '',
            'line_item_warehouse': row_data['Line Item Warehouse'] ? row_data['Line Item Warehouse'] : '',
            'linked_po_number': row_data['Linked PO Number'] ? (row_data['Linked PO Number'].length < 6 ? '0' + String(row_data['Linked PO Number']) : String(row_data['Linked PO Number'])).replace('.','') : '',
            'salesman_code': row_data['Salesman Code'] ? row_data['Salesman Code'] : '',
            'order_writer_name': row_data['Order Writer Name'] ? row_data['Order Writer Name'] : '',
            'line_item_whse_exp_po_date': row_data['Line Item Whse Exp PO Date'] ? Moment(row_data['Line Item Whse Exp PO Date'], 'MM-DD-YYYY').format('M/DD/YY') : '',
            'ship_to_city': row_data['Shipto City'] ? row_data['Shipto City'] : '',
            'hipri_order': row_data['HiPri Order?'] ? row_data['HiPri Order?'] : '',
            'order_part_of_group_shipment': row_data['Is Order Part of Group Shipment?'] ? row_data['Is Order Part of Group Shipment?'] : '',
            'group_shipment_id': row_data['Group Shipment ID Number'] ? row_data['Group Shipment ID Number'] : '',
            'shipping_company': row_data['Shipping Company'] ? row_data['Shipping Company'] : '',
            'tracking_number': row_data['Tracking Number'] ? row_data['Tracking Number'] : '',
            'shipping_notes': row_data['Shipping Notes'] ? row_data['Shipping Notes'] : '',
            'substitute_1': row_data['Substitute 1'] ? row_data['Substitute 1'] : '',
            'substitute_2': row_data['Substitute 2'] ? row_data['Substitute 2'] : '',
            'substitute_3': row_data['Substitute 3'] ? row_data['Substitute 3'] : '',
            'complete_order_status': row_data['Complete Order Status'] ? row_data['Complete Order Status'] : '',
            'ship_via_code': row_data['Ship Via Code'] ? row_data['Ship Via Code'] : '',
            'ship_date': row_data['Ship Date'] ? row_data['Ship Date'] : '',
            'file_provided_drop_ship': row_data['Drop Ship?'] ? row_data['Drop Ship?'] : '',
            'white_glove_support_order': row_data['White Glove Support Order?'] === 'Y' ? true : false,
            'white_glove_support_description': row_data['If white glove describe what is needed'] ? row_data['If white glove describe what is needed'] : '',
            'email_address_of_ordered_by': row_data['Email Address'] ? row_data['Email Address'] : '',
            'completed': parseInt(row_data['Quantity Backordered']) > 0 ? false : true,
            'upload_by': self.user,
            'file_date': upload_date,
            'most_recently_included_in_imported_file_date': upload_date
        }
        new_backorders_file_object[file_backorder_line.backorder_key] = file_backorder_line
      }

      var backorder_columns = Object.keys(file_backorder_line)
      
      for(var b in new_backorders_file_object){
        var new_backorder_line = new_backorders_file_object[b]
        
        new_upload_sales_orders_arr.push(new_backorder_line.order_number)

        if(current_sales_orders_arr.indexOf(new_backorder_line.order_number) == -1){
          var new_sales_order = {
            most_recently_included_in_imported_file_date: upload_date,
            sales_order_number: new_backorder_line.order_number,
            sales_order_date: new_backorder_line.order_date,
            customer_name: new_backorder_line.customer_name,
            white_glove_support_order: new_backorder_line.white_glove_support_order,
            white_glove_support_description: new_backorder_line.white_glove_support_description,
            open: true,
            complete_order_status: new_backorder_line.complete_order_status,
            ship_date: new_backorder_line.ship_date,
            ship_to_city: new_backorder_line.ship_to_city,
            shipping_company: new_backorder_line.shipping_company,
            tracking_number: new_backorder_line.tracking_number,
            ship_via_code: new_backorder_line.ship_via_code,
            customer_po: new_backorder_line.customer_po,
            ordered_by_name: new_backorder_line.ordered_by_name,
            order_writer_name: new_backorder_line.order_writer_name,
            email_address_of_ordered_by: new_backorder_line.email_address_of_ordered_by
          }
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}`] = new_sales_order
        }else{        
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/most_recently_included_in_imported_file_date`] = upload_date
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/white_glove_support_order`] = new_backorder_line.white_glove_support_order
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/white_glove_support_description`] = new_backorder_line.white_glove_support_description
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/complete_order_status`] = new_backorder_line.complete_order_status
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/open`] = true
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/completed_date`] = null
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/ship_date`] = new_backorder_line.ship_date
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/ship_to_city`] = new_backorder_line.ship_to_city
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/shipping_company`] = new_backorder_line.shipping_company
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/tracking_number`] = new_backorder_line.tracking_number
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/ship_via_code`] = new_backorder_line.ship_via_code
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/customer_po`] = new_backorder_line.customer_po
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/ordered_by_name`] = new_backorder_line.ordered_by_name
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/order_writer_name`] = new_backorder_line.order_writer_name
          updates_for_db[`/sales_orders/${new_backorder_line.order_number}/email_address_of_ordered_by`] = new_backorder_line.email_address_of_ordered_by
        }
        
        if(!current_db_backorders_clone[b]){
          if(new_backorder_line.completed){
            new_backorder_line.backorder_marked_complete = upload_date        
          }
          updates_for_db[`/backorders/${b}`] = new_backorder_line;
        }else{
          var current_backorder_clone = JSON.parse(JSON.stringify(current_db_backorders_clone[b])) // grab existing db line

          for(var y = 0; y < backorder_columns.length; y++){
            var column = backorder_columns[y]
            current_backorder_clone[column] = new_backorder_line[column]
          }

          if(new_backorder_line.completed && !current_backorder_clone.completed){
            current_backorder_clone.completed = true
            current_backorder_clone.backorder_marked_complete = upload_date
          }
          updates_for_db[`/backorders/${b}`] = current_backorder_clone;
        }
      }

      for(var ob in current_db_backorders_clone){
        if(!current_db_backorders_clone[ob].completed && !new_backorders_file_object[ob]){
          updates_for_db[`/backorders/${ob}/completed`] = true;
          updates_for_db[`/backorders/${ob}/backorder_marked_complete`] = upload_date
        }
      }

      for(var z = 0; z < current_sales_orders_arr.length; z++){
        var in_latest_file = new_upload_sales_orders_arr.indexOf(current_sales_orders_arr[z]) !== -1
        if(!in_latest_file){
          updates_for_db[`/sales_orders/${current_sales_orders_arr[z]}/open`] = false
          updates_for_db[`/sales_orders/${current_sales_orders_arr[z]}/completed_date`] = upload_date
        }
        
      }

      firebase.database().ref().update(updates_for_db).then(function() {
        firebase.database().ref('last_backorder_file_upload').set(file_date)
        firebase.database().ref('last_backorder_file_pull').set(upload_date)
        self.filelist = []
        self.$store.state.isLoading = false
      }).catch(function(error) {
        console.log("Error updating data:", error);
      });


    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .dropzone{
    width: 100%;
    height: 50px;
    border: 2px dashed orange;
    border-radius: 4px;
  }
  .bg-white{
    background: white;
  }
  .bg-orange{
    background: orange;
  }
</style>
