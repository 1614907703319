<template>
  <v-dialog scrollable persistent v-model="dialog">
    <v-card width="90vw">

      <v-card-text>

        <v-card-title style="margin-left:-15px">Add Internal Note for Sales Order {{salesorder}}</v-card-title>

        <v-textarea variant="outlined" height="20" :placeholder="'Note'" v-model="note"></v-textarea>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="background:gray;color:white" @click="$emit('close')">CANCEL</v-btn>
        <v-btn style="background:#ff7214;color:white" @click="saveNote()" :disabled="note.trim() === ''">SAVE</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'

export default {
  name: 'NoteSalesOrderPopUp',
  components: {},
  mixins: [],
  props:{
    salesorder: String
  },
  data(){
    return{
      dialog: true,
      note: ''
    }
  },
  beforeMount(){

  },
  computed: {
    ...mapGetters(['user','db']),
  },
  methods:{
    saveNote(){
      var self = this
      var submitted_by_name = self.db.users[self.user].first_name + ' ' + self.db.users[self.user].last_name
      firebase.database().ref('sales_orders').child(self.salesorder).child('internal_notes').push({note: self.note, submitted_by: self.user, submitted_by_name: submitted_by_name, submitted_date: Date.now()}).then(function(){
        self.$emit('close')
      })
    }
  }
}
</script>

<style>

</style>


