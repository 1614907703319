<template>
  <div class="vendor">

    <div v-if="showTable">

      <div style="margin-top:10px">

        <div style="margin-bottom:10px">
          <v-row>
            <v-col cols="3">
              <v-text-field label="Search Backorders..." variant="solo" density="compact" hide-details="true" @input="debouncedSearch($event)" style=""></v-text-field>
            </v-col>

            <v-col cols="3">
              <v-select
                :items="salesperson_arr"
                label="Salesperson"
                v-model="selectedSalesperson"
                density="compact"
                hide-details="auto"
              ></v-select>
            </v-col>

            <v-col cols="3">
              <v-select
                :items="order_writers_arr"
                label="Order Writer"
                v-model="selectedOrderWriter"
                density="compact"
                hide-details="auto"
              ></v-select>
            </v-col>

            <v-col cols="3" style="display: flex; flex-direction: column; justify-content: flex-end; align-items: flex-end;">
              <div style="font-size:12px;font-style:italic;">Last Imported Backorders File Date: {{lastUploadDate}}</div>
              <div style="font-size:12px;font-style:italic;line-height:100%;">* = Unlinked PO</div>
            </v-col>

            <v-col cols="12" style="padding-top:0px;padding-bottom:2px">
              <v-checkbox
                v-model="filter_missing_or_past_due_etas"
                density="compact"
                hide-details="auto"
                :style="{ color: '#ff7214' }">
              <template v-slot:label>
                <span style="color:black">Missing or Past Due ETAs Only</span>
              </template>              
              </v-checkbox>
            </v-col>
          </v-row>
 
        </div>

        <Vue3EasyDataTable
          :headers="headers"
          :items="customer_backorders"
          :search-value="searchValue"
          :sort-by="sortBy"
          :sort-type="sortType"
          :hide-footer="false"
          :rows-per-page="250"
          :rows-items="[250, 500, 1000, 5000]"
          :hide-rows-per-page="true"
          :table-height="table_height"
          :empty-message="data_message"
        >

          <template #header-critical_item="header">
            <div>
              <div>{{header.text}}</div>
            </div>
          </template>

          <template #header-order_writer_name="header">
            <div>
              <div>{{header.text}}</div>
            </div>
          </template>

          <template #header-linked_po_number="header">
            <div>
              <div>{{header.text}}</div>
            </div>
          </template>

          <template #header-internal_notes="header">
            <div>
              <div>{{header.text}}</div>
            </div>
          </template>

          <template #header-vendor="header">
            <div>
              <div>{{header.text}}</div>
            </div>
          </template>

          <template #item-critical_item="item">
            <div class="critical_btn" @click="flagCritical(item.backorder_key)" v-if="!item.critical_item"></div>
            <div class="critical_btn_active" @click="unflagCritical(item.backorder_key)" v-if="item.critical_item"></div>
          </template>

          <template #item-customer_name="item">
            <router-link :to="{ name: 'customer', params: {customerpageid: db.customers['customer_'+item.customer_number].page}}" target="_blank" style="color:#ff7214;font-weight:bold">{{item.customer_name}}</router-link>
          </template>

          <template #item-linked_po_number="item">
            <span v-if="item.po_for_detail" style='color:#ff7214;cursor:pointer;font-weight:bold' @click="openPurchaseOrderDetailPopup(item.po_for_detail)">{{item.linked_po_number}}</span>
            <span v-if="!item.po_for_detail">{{item.linked_po_number}}</span>
          </template>

          <template #item-order_date_sortable="item">
            {{item.order_date_formatted}}
          </template>

          <template #item-substitutes="item">
            <div>{{item.substitute_1}}</div>
            <div>{{item.substitute_2}}</div>
            <div>{{item.substitute_3}}</div>
          </template>

          <template #item-vendor="item">
            <router-link v-if="item.vendor_page" :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page}}" target="_blank" style="color:#ff7214;font-weight:bold">{{item.vendor}}</router-link>
            <span v-if="!item.vendor_page">{{item.vendor}}</span>
          </template>

          <template #item-ordered_by_name="item">
            <div>{{item.ordered_by_name}}</div>
            <div style="font-style:italic;font-size:10px">{{item.email_address_of_ordered_by}}</div>
          </template>

          <template #item-sortable_eta="item">

            <div v-if="item.non_current_eta && item.approx_eta_set" style="background:red;color:white;font-weight:bold;padding:4px;text-decoration:underline">{{item.eta}}</div>
            <div v-if="item.non_current_eta && !item.approx_eta_set" style="background:red;color:white;font-weight:bold;padding:4px;">{{item.eta}}</div>

            <div v-if="!item.non_current_eta" style="padding:4px">
              <div v-if="item.approx_eta_set" style="text-decoration:underline">{{item.eta}}</div>
              <div v-if="!item.approx_eta_set">{{item.eta}}</div>
            </div>
          </template>

          <template #item-internal_notes="item">
            <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;min-width:100px">
              <div style="font-style:italic">{{n.note}}</div>
              <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}} {{n.submitted_by_email}}</div>
            </div>
            <v-btn style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px" @click="backorderNote('internal',item.id)">ADD NOTE</v-btn>
          </template>

          <template #item-inquiries="item">
            <div v-for="n in item.inquiries" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;min-width:100px">
              <div style="font-style:italic">{{n.note}}</div>
              <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}} {{n.submitted_by_email}}</div>
            </div>
            <v-btn style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px" @click="backorderNote('shared',item.id)">ADD NOTE</v-btn>
          </template>

        </Vue3EasyDataTable>
        
      </div>

    </div>
    <span class="version-footer">
      {{$store.state.version}}
    </span>
    <FaqPopUp v-if="faq_popup" @close="faq_popup = false" />
    <FeedbackPopUp v-if="feedback_popup" :customer='customer_for_component' @close="feedback_popup = false" />
    <PurchaseOrderDetailPopUp v-if="po_for_component" :po='po_for_component' @close="closePurchaseOrderDetailPopup()" />
    <BackorderInquiryPopUp v-if="backorder_item_inquiry_key" @close="backorder_item_inquiry_key = false" :backorder='backorder_item_inquiry_key'/>
    <BackorderNotePopUp v-if="backorder_note_popup" @close="backorder_note_popup = false" :backorderkey="backorder_key_for_component" :type="backorder_note_type"/>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { debounce } from 'lodash';
import BackorderInquiryPopUp from '@/components/BackorderInquiryPopUp.vue'
import PurchaseOrderDetailPopUp from '@/components/PurchaseOrderDetailPopUp.vue'
import FeedbackPopUp from '@/components/FeedbackPopUp.vue'
import BackorderNotePopUp from '@/components/BackorderNotePopUp.vue'
import FaqPopUp from '@/components/FaqPopUp.vue'

import XLSX from 'xlsx'

export default {
  name: 'BackorderList',
  components: {Vue3EasyDataTable,BackorderInquiryPopUp,PurchaseOrderDetailPopUp,BackorderNotePopUp,FeedbackPopUp,FaqPopUp},
  data(){
    return {
      feedback_popup: false,
      backorder_note_popup: false,
      backorder_note_type: null,
      table_height: 1200,
      searchValue: '',
      sortBy: 'customer_name',
      sortType: 'desc',
      backorder_item_inquiry_key: false,
      showTable: false,
      po_for_component: false,
      faq_popup: false,
      data_message: 'No data',
      order_writers_arr: ['All'],
      salesperson_arr: ['All'],
      selectedOrderWriter: 'All',
      selectedSalesperson: 'All',
      filter_missing_or_past_due_etas: false
    }
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeMount(){
    var self = this
    self.$store.state.isLoading = true
    setTimeout(() => {
      self.showTable = true;
      self.$store.state.isLoading = false
    }, 2000);
  },
  computed: {
    ...mapGetters(['user','db','po_confirmation_status','item_eta_status']),
    lastUploadDate(){
      return Moment(this.db.last_backorder_file_upload).format('MM/DD/YY hh:mma')
    },
    headers(){
      return [
        // DON'T FORGET TO MAKE EDITS TO THE EXCEL EXPORT IF YOU UPDATE HEADERS
        { text: "Critical Item", value: "critical_item", sortable: true },
        { text: "Customer Name", value: "customer_name", sortable: true },
        { text: "Riverhawk Sales Order Number", value: "order_number", sortable: true },
        { text: "Order Date", value: "order_date_sortable", sortable: true },
        { text: "Riverhawk Product Number", value: "product_number", sortable: true },
        { text: "Description", value: "description_line_one", sortable: true },
        { text: "ETA", value: "sortable_eta", sortable: true },
        { text: "Internal Notes", value: "internal_notes", sortable: false },
        { text: "Customer PO Number", value: "customer_po", sortable: true },
        { text: "Ordered By", value: "ordered_by_name", sortable: true },
        { text: "Order Writer", value: "order_writer_name", sortable: true },
        { text: "Salesperson", value: "salesman_code", sortable: true },
        { text: "Product Substitutes", value: "substitutes", sortable: false },
        { text: "Ship To City", value: "ship_to_city", sortable: true },
        { text: "Shipping Company", value: "shipping_company", sortable: true },
        { text: "Tracking Number", value: "tracking_number", sortable: true },
        { text: "Qty Backordered", value: "quantity_backordered", sortable: true },
        { text: "UOM", value: "unit_of_measure", sortable: true },
        { text: "Dropship?", value: "dropship", sortable: true },
        { text: "Linked PO Number", value: "linked_po_number", sortable: true },
        { text: "Vendor", value: "vendor", sortable: true },
        
        { text: "Shared Notes", value: "inquiries", sortable: false }
      ]
    },
    customer_backorders(){
      var backorders_db_clone = this.db.backorders ? JSON.parse(JSON.stringify(this.db.backorders)) : {}
      var backorders = []

      for(var b in backorders_db_clone){
        var backorder = backorders_db_clone[b]
        backorder.id = b
        backorder.missing_linked_po_number = !backorder.linked_po_number || backorder.linked_po_number.trim() === ""
        if(!backorder.completed){
          backorder.dropship = backorder.line_item_warehouse === '09' ? 'YES' : 'NO'
          backorder.critical_item = backorder.critical_item ? true : false

          if (this.order_writers_arr.indexOf(backorder.order_writer_name) === -1) {
            this.order_writers_arr.push(backorder.order_writer_name);
          }

          if (this.salesperson_arr.indexOf(backorder.salesman_code) === -1) {
            this.salesperson_arr.push(backorder.salesman_code);
          }

          this.order_writers_arr = ['All', ...this.order_writers_arr.filter(name => name !== 'All').sort()];
          this.salesperson_arr = ['All', ...this.salesperson_arr.filter(code => code !== 'All').sort()];


          if(this.db.purchase_orders[backorder.linked_po_number] ){
            backorder.po_for_detail = backorder.linked_po_number
            backorder.vendor = this.db.purchase_orders[backorder.linked_po_number].vendor_name
            backorder.vendor_number = this.db.purchase_orders[backorder.linked_po_number].vendor_number
            backorder.vendor_page = this.db.vendors['vendor_'+backorder.vendor_number].page
            backorder.eta = 'Item Not Found In Nest PO'
            backorder.non_current_eta = false
            backorder.has_linked_po = true

            for(var item_key in this.db.purchase_orders[backorder.linked_po_number].items){

              if(this.db.purchase_orders[backorder.linked_po_number].items[item_key].product === backorder.product_number){
                backorder.eta = this.db.purchase_orders[backorder.linked_po_number].items[item_key].eta
                
                if(this.db.purchase_orders[backorder.linked_po_number].items[item_key].line_complete === 'Y'){
                  backorder.eta = 'Complete'
                }

                if(this.db.purchase_orders[backorder.linked_po_number].items[item_key].approx_eta_set){
                  backorder.approx_eta_set = true
                }

                if(backorder.eta === 'UTP'){
                  backorder.eta = 'Vendor Unable To Provide Date'
                }

                if(backorder.eta === 'TBD'){
                  backorder.eta = 'ETA TBD'
                }

                if(backorder.eta === ''){
                  backorder.eta = this.item_eta_status(this.db.purchase_orders[backorder.linked_po_number],item_key)
                }
              }

            }

          }else{

            if(backorder.missing_linked_po_number){
              backorder.has_linked_po = false

              var potential_etas = []
              for(var po in this.db.purchase_orders){

                if (/[a-zA-Z]/.test(po)) { //make sure PO is not a special order with a letter
                  continue;
                }

                for(var item in this.db.purchase_orders[po].items){
                  if(this.db.purchase_orders[po].items[item].product === backorder.product_number  && this.db.purchase_orders[po].items[item].line_complete !== 'Y'){
                    potential_etas.push(this.db.purchase_orders[po].items[item].eta + '|' + this.db.purchase_orders[po].po_number + '|'  + this.db.purchase_orders[po].vendor_name + '|'  + this.db.purchase_orders[po].vendor_number + '|'  + this.db.vendors['vendor_'+this.db.purchase_orders[po].vendor_number].page )
                  }
                }
              }

              var hasEmptyString = false;
              var hasUTPValue = false;
              var hasTBDValue = false;
              var earliestDate = null;
              var po;
              var vendor;
              var vendor_number;
              var vendor_page;
              var po_for_detail;

              for (var i = 0; i < potential_etas.length; i++) {
                const eta = potential_etas[i].split('|')[0];
                const p = potential_etas[i].split('|')[1];
                const v = potential_etas[i].split('|')[2];
                const v_number = potential_etas[i].split('|')[3];
                const v_page = potential_etas[i].split('|')[4];

                if (eta === '') {
                  hasEmptyString = true;
                  po = p
                  vendor = v
                  vendor_number = v_number
                  vendor_page = v_page
                  po_for_detail = po
                  continue;
                }

                if (eta === 'UTP') {
                  hasUTPValue = true;
                  po = p
                  vendor = v
                  vendor_number = v_number
                  vendor_page = v_page
                  po_for_detail = po
                  continue;
                }

                if (eta === 'TBD') {
                  hasTBDValue = true;
                  po = p
                  vendor = v
                  vendor_number = v_number
                  vendor_page = v_page
                  po_for_detail = po
                  continue;
                }

                const date = Moment(eta, 'M/DD/YY');

                if (earliestDate === null || date.isBefore(earliestDate, 'day')) {
                  earliestDate = date;
                  po = p
                  vendor = v
                  vendor_number = v_number
                  vendor_page = v_page
                  po_for_detail = po
                }
              }

              if (earliestDate === null) {
                if (hasUTPValue) {
                  backorder.eta = 'Vendor Unable To Provide Date';
                  backorder.linked_po_number = po + ' *'
                  backorder.vendor = vendor + ' *'

                  backorder.vendor_number = vendor_number
                  backorder.vendor_page = vendor_page
                  backorder.po_for_detail = po_for_detail
                } else if (hasTBDValue) {
                  backorder.eta = 'ETA TBD';
                  backorder.linked_po_number = po + ' *'
                  backorder.vendor = vendor + ' *'

                  backorder.vendor_number = vendor_number
                  backorder.vendor_page = vendor_page
                  backorder.po_for_detail = po_for_detail
                } else if (hasEmptyString) {
                  backorder.eta = 'Pending ETA From Vendor';
                  backorder.linked_po_number = po + ' *'
                  backorder.vendor = vendor + ' *'

                  backorder.vendor_number = vendor_number
                  backorder.vendor_page = vendor_page
                  backorder.po_for_detail = po_for_detail
                } else {
                  backorder.eta = this.user !== 'vendor' ? 'No PO Found' : 'PO Conf Pending'
                  backorder.linked_po_number = this.user !== 'vendor' ? 'No PO Found' : 'PO Conf Pending'
                  backorder.vendor = this.user !== 'vendor' ? 'No PO Found' : 'PO Conf Pending'

                  backorder.vendor_number = false
                  backorder.vendor_page = false
                  backorder.po_for_detail = false
                }
                backorder.non_current_eta = true
              } else {
                  backorder.eta = earliestDate.format('M/DD/YY');
                  backorder.linked_po_number = po + ' *'
                  backorder.vendor = vendor + ' *'

                  backorder.vendor_number = vendor_number
                  backorder.vendor_page = vendor_page
                  backorder.po_for_detail = po_for_detail
              }              
            }

            if(!backorder.missing_linked_po_number){
              backorder.eta = 'Linked PO not open in Nest'
              backorder.vendor = 'Linked PO not open in Nest'
            }

          }

          if (Moment(backorder.eta, 'M/DD/YY').valueOf() < Moment().startOf('day').valueOf()) {
            backorder.eta = '(' + backorder.eta + ') Past Due, Update Pending';
            backorder.non_current_eta = true
          }

          if(backorder.eta == 'PO Conf Pending' || backorder.eta == 'Vendor Unable To Provide Date' || backorder.eta == 'Not Requested' || backorder.eta == 'PO Disputed'){
            backorder.non_current_eta = true
          }

          if(!backorder.has_linked_po && this.user !== 'vendor' && backorder.eta !== 'Linked PO not open in Nest'){
            backorder.eta = backorder.eta + ' *'
          }

          const orderWriterMatches = this.selectedOrderWriter === "All" || backorder.order_writer_name === this.selectedOrderWriter;
          const salespersonMatches = this.selectedSalesperson === "All" || backorder.salesman_code === this.selectedSalesperson;
          const etaMatches = this.filter_missing_or_past_due_etas === false || backorder.non_current_eta

          backorder.sortable_eta = Moment(this.parseETA(backorder.eta)).startOf('day')
          
          backorder.order_date_sortable = Moment(backorder.order_date, 'M/D/YY').format('YYYY-MM-DD');
          backorder.order_date_formatted = Moment(backorder.order_date, 'M/D/YY').format('MM/DD/YY');

          if (orderWriterMatches && salespersonMatches && etaMatches) {
            backorders.push(backorder);
          }
          
        }
      }
      return backorders
    }
  },
  methods: {
    statusClass(status) {
      switch (status) {
        case 'Complete':
          return 'status-complete';
        case 'Due Soon':
          return 'status-due-soon';
        case 'Due Now':
          return 'status-due-now';
        default:
          return '';
      }
    },      
    parseETA(eta){
      // Regular expression to match date formats like "MM/DD/YY" or "MM/DD/YYYY"
      const dateRegex = /(\d{1,2})\/(\d{1,2})\/(\d{2}|\d{4})/;

      // Check if the eta string matches the date pattern
      const match = eta.match(dateRegex);

      // If a match is found, return the matched date string
      if (match) {
        return match[0]; // The first element is the exact matched date string
      }

      // Return "1/1/00" if no valid date is found
      return '1/1/00';
    },
    backorderNote(type,id){
      this.backorder_note_type = type
      this.backorder_key_for_component = id
      this.backorder_note_popup = true
    },
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 225
    },
    debouncedSearch: debounce(function(event) {
      this.searchValue = event.target._value.replace(/\*/g, "");
    }, 500),
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    flagCritical(item){
      var self = this
      var confirm_flag = confirm('Are you sure you want to flag ' + this.db.backorders[item].product_number + '?')
      if(confirm_flag){
        firebase.database().ref('backorders').child(item).child('critical_history').push({'set_to': true, 'set_by': (self.user !== 'vendor' ? self.user : 'customer'), 'set_date': Date.now()})
        firebase.database().ref('backorders').child(item).child('critical_item').set(true)
      }
    },
    unflagCritical(item){
      var self = this
      var confirm_unflag = confirm('Are you sure you want to unflag ' + this.db.backorders[item].product_number + '?')
      if(confirm_unflag){
        firebase.database().ref('backorders').child(item).child('critical_history').push({'set_to': false, 'set_by': (self.user !== 'vendor' ? self.user : 'customer'), 'set_date': Date.now()})
        firebase.database().ref('backorders').child(item).child('critical_item').set(false)
      }
    },
    openPurchaseOrderDetailPopup(po){
      this.po_for_component = po
    },
    closePurchaseOrderDetailPopup(){
      this.po_for_component = false
    },
  }
}
</script>

<style scoped>

  .vendor{
    margin: 20px;
  }

  table{
    font-size: 12px;
  }
  th {
    text-align: left;
    padding-right: 20px;
  }
  td {
    text-align: left;
    padding-right: 20px;
  }

  .table-container{
    padding: 15px;
  }

  .table-title{
    padding-top: 15px;
  }

  .critical_btn{
    border: 1px solid #ff7214;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .critical_btn_active{
    background: #ff7214;
    border: 1px solid #ff7214;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 16px;
  }

  .critical_btn_active::after{
      content: "✔";
  }

  .riverhawk-header{
    background: #ff7214;
    height: 40px;
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
  }

  .riverhawk-header-text{
    color: white;
    font-weight: bold;
    padding: 5px;
  }

.status-complete {
    color: green;
    width: 70px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    margin-left: 5px;
}

.status-due-soon {
    color: #FFA500;
    width: 70px;
    text-align: center;
    font-weight: bold;
    border-radius: 2px;
    font-size: 12px;
    margin-left: 5px;
}

.status-due-now {
    color: red;
    width: 70px;
    text-align: center;
    font-weight: bold;
    border-radius: 2px;
    font-size: 12px;
    margin-left: 5px;
}
</style>
