<template>
  <div class="purchase-orders-import">
    <div class="dropzone bg-white" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <input type="file" name="fields[assetsFieldHandle_po][]" id="assetsFieldHandle_po" @change="onChange" ref="file" style="opacity: 0;display:none"/>
      <div v-if="!filelist.length" style="padding-top:10px;padding-left:25px;color:gray;display:flex;align-items:center;">
        <v-icon class="mdi mdi-file-upload-outline orange--text"></v-icon>
        <span style="padding-left:10px;">Purchase Orders File Drop</span>
      </div>
    </div>
    <div style="color:red;font-size:10px;font-weight:bold;padding-top:5px">Vendors should be updated before Purchase Orders</div>
    <div style="display:flex;justify-content:space-between;align-items:center;margin-top:10px;position:relative;">
      <v-btn style="background:#ff7214;color:white;height:15px;width:75px;font-size:8px;margin-right:10px;" @click="pullData()">PULL DATA</v-btn>
      <div>
        <div style="font-size:12px;color:gray;font-style:italic;">Last Imported File Date: {{lastUploadDate}}</div>
        <div style="font-size:12px;color:gray;font-style:italic;">Last Pull Data Date: {{lastPullDate}}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as XLSX from "xlsx"
import Papa from "papaparse"
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import AWS from 'aws-sdk';

export default {
  name: 'PurchaseOrdersImport',
  components: {},
  data(){
    return {
      filelist: [],
    }
  },
  beforeMount() {

  },
  watch: {
    filelist(val){
      if(val.length > 0){
        this.readFiles()
      }
    }
  },
  computed: {
    ...mapGetters(['user','db']),
    lastUploadDate(){
      return Moment(this.db.last_purchase_order_file_upload).format('MM/DD/YY hh:mma')
    },
    lastPullDate(){
      return this.db.last_purchase_order_file_pull ? Moment(this.db.last_purchase_order_file_pull).format('MM/DD/YY hh:mma') : false
    }    
  },
  methods: {
    async pullData(){
      AWS.config.region = 'us-east-1';

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-east-1:44b121e3-3117-4e3a-b0b1-a9ea0abf7772',
      });

      AWS.config.credentials.get(() => {
        const s3 = new AWS.S3();

        const params = {
          Bucket: 'cloudmailing-files',
          Prefix: 'purchase-orders/'
        };

        s3.listObjectsV2(params, (err, data) => {
          if (err) {
            console.error(err);
          } else {
            const files_arr = data['Contents'].sort((a, b) => (a.LastModified < b.LastModified) ? 1 : ((b.LastModified < a.LastModified) ? -1 : 0));
            const fileKey = files_arr[0].Key;
            const s3Params = { Bucket: 'cloudmailing-files', Key: fileKey };

            var most_recent_file_date = Moment(new Date(files_arr[0].LastModified));
            var today_date = Moment(new Date());
            var is_same_day = most_recent_file_date.isSame(today_date, 'day');
            var shouldProceed = is_same_day;
            var file_date = Moment(files_arr[0].LastModified, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').valueOf();

            if (!is_same_day) {
                var msg = 'This file is from ' + most_recent_file_date.format('MM-DD-YYYY') + '. Today\'s file has not loaded to the cloud yet. Do you want to proceed with loading the older file?'
                shouldProceed = confirm(msg);
            }

            if (shouldProceed) {
              s3.getObject(s3Params, (err, data) => {
                if (err) {
                  console.error(err);
                } else {
                  const file = new File([data.Body], fileKey);
                  this.writeInitialData(file,file_date);
                }
              });
            }
          }
        });
      });
    },
    searchFiles(){
      document.getElementById("assetsFieldHandle_po").click()
    },
    onChange() {
      var files_arr = [...this.$refs.file.files];
      files_arr = _.sortBy(files_arr, (obj) => parseInt(obj.name, 10));
      this.filelist = files_arr;
      this.$store.state.isLoading = true
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-orange')) {
        event.currentTarget.classList.remove('bg-white');
        event.currentTarget.classList.add('bg-orange');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-white');
      event.currentTarget.classList.remove('bg-orange');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      event.currentTarget.classList.add('bg-white');
      event.currentTarget.classList.remove('bg-orange');
      this.onChange();
    },
    readFiles(){
      for(var i = 0; i < this.filelist.length; i++){
        var file_name = this.filelist[i].name.toLowerCase()
        if(this.filelist[i].type.indexOf('text/csv') !== -1 && file_name.indexOf('po') !== -1){
          this.writeInitialData(this.filelist[i], Date.now())
        }else{
          this.remove(this.filelist[i])
          alert('Invalid File(s)')
          this.$store.state.isLoading = false
        }
      }
    },
    writeInitialData(file,file_date){


      const eventTime = Moment(this.db.last_po_confirmations_and_eta_requests_triggered);  // Convert timestamp to moment object
      const currentTime = Moment();  // Current time
      const diffInMinutes = currentTime.diff(eventTime, 'minutes');  // Difference in minutes

      if(diffInMinutes <= 5){
        alert('The PO Confirmation reminders and ETA Requests was recently triggered. Please try again in ' + (5 - diffInMinutes) + ' minutes.')
        this.remove(this.filelist[0])
        this.$store.state.isLoading = false        
        return
      }

      var reader = new FileReader();
      var self = this
      self.$store.state.isLoading = true
      reader.readAsBinaryString(file);

      reader.onload = function(e) {
        var data = e.target.result;
        var parsedData = Papa.parse(data, {
          header: true, // Set header to true to parse the first row as headers
          dynamicTyping: false, // Disable automatic type casting
          skipEmptyLines: true, // Skip empty lines
        });

        var json = parsedData.data;

        if (json.length === 0) {
          self.$store.state.isLoading = false;
          alert('Invalid File(s)');
          return;
        }

        setTimeout(function(){
          self.updateDatabase(json,file_date)
        },1000)
      }
    },
    updateDatabase(json,file_date){

      var self = this
      var current_db_purchase_orders_clone = self.db.purchase_orders ? JSON.parse(JSON.stringify(self.db.purchase_orders)) : {}
      var new_upload_purchase_orders = {}

      for(var i = 0; i < json.length; i++){
        var row_data = json[i]
        var po_number = (row_data['PO Number'].length < 6 ? '0' + String(row_data['PO Number']) : String(row_data['PO Number'])).replace('.','')
        var po_info = {
            'po_number': po_number,
            'buyer_name': row_data['Buyer Name'],
            'po_date': Moment(row_data['PO Date'], 'MM-DD-YYYY').format('M/DD/YY'),
            'vendor_name': row_data['Vendor Name'],
            'vendor_number': String(parseInt(row_data['Vendor Number'])),
            'internal_notes': '',
            'items': {},
            'upload_date': Date.now(),
            'upload_by': self.user,
            'action_po_conf_request_cnt': 0,
            'action_po_complete': 'N'
        }

        po_info['action_vendor_po_conf_response'] = (self.db.vendors['vendor_'+po_info.vendor_number].self_confirm_po || self.db.vendors['vendor_'+po_info.vendor_number].po_confirmation_email === 'email missing') ? 'Pending' : 'Not Requested'

        var line_info = {
          'product': row_data['Product'],
          'product_description': row_data['Product Description'] ? row_data['Product Description'] : '',
          'po_quantity': row_data['PO Quantity'] ? row_data['PO Quantity'] : 0,
          'qty_open': row_data['Qty Open'] ? row_data['Qty Open'] : 0,
          'qty_rcv': row_data['Qty Rcv'] ? row_data['Qty Rcv'] : 0,
          'uom': row_data['UOM'] ? row_data['UOM'] : '',
          'unit_price': row_data['Unit Price'] ? row_data['Unit Price'] : 0,
          'manufacturer_part_number': row_data['Manufacturer Part Number'] ? row_data['Manufacturer Part Number'] : '',
          'vendor_part_number': row_data['Primary Vendor Part Number'] ? row_data['Primary Vendor Part Number'] : '',
          'line_number': row_data['Line Number'],
          'ship_via': row_data['Ship Via'] ? row_data['Ship Via'] : '',
          'shipping_company': '',
          'tracking_number': '',
          'shared_notes': '',
          'internal_notes': '',
          'line_complete': row_data['Line Complete'] ? row_data['Line Complete'] : 'N',
          'eta_escalation_requests_cnt' : 0,
          'eta': '', // ('','Unable To Provide','xx/xx/xx')
          'po_status': row_data['PO Status'],
          'drop_ship': row_data['Whse'] === '9' ? true : false,
          'ship_to_name': row_data['Ship To Name'] ? row_data['Ship To Name'] : ''
        }

        if(line_info['line_number'] && line_info['product']){
          var line_item_id = line_info['line_number'] + '-' + line_info['product'].replace(/[^0-9a-z]/gi, '')
          line_info['item_key'] = line_item_id

          if(!new_upload_purchase_orders[po_number]){
            new_upload_purchase_orders[po_number] = po_info
          }
          new_upload_purchase_orders[po_number]['items'][line_item_id] = line_info
        }

      }

      var updates = {};

      for(var po_number in new_upload_purchase_orders){ // iterate through new_upload_purchase_orders to see what is new or needs to be edited
        if(current_db_purchase_orders_clone[po_number]){ // is it a  PO already in database?
          var current_db_keys = Object.keys(current_db_purchase_orders_clone[po_number].items).sort();
          var new_file_keys = Object.keys(new_upload_purchase_orders[po_number].items).sort();
          var current_and_new_purchase_orders_match = JSON.stringify(current_db_keys) === JSON.stringify(new_file_keys) && current_db_purchase_orders_clone[po_number].confirmation_status !== 'Completed'


          if(current_and_new_purchase_orders_match){ // EDIT EXISTING PURCHASE ORDER IN DB WITH DATA FROM NEW UPLOAD
            for(var item in current_db_purchase_orders_clone[po_number].items){
              var item_clone = JSON.parse(JSON.stringify(current_db_purchase_orders_clone[po_number].items[item]))
              // item_clone.eta set by vendor so don't change it
              item_clone.line_complete = new_upload_purchase_orders[po_number]['items'][item].line_complete
              item_clone.line_number = new_upload_purchase_orders[po_number]['items'][item].line_number
              item_clone.manufacturer_part_number = new_upload_purchase_orders[po_number]['items'][item].manufacturer_part_number
              item_clone.vendor_part_number = new_upload_purchase_orders[po_number]['items'][item].vendor_part_number
              item_clone.po_quantity = new_upload_purchase_orders[po_number]['items'][item].po_quantity
              item_clone.product = new_upload_purchase_orders[po_number]['items'][item].product
              item_clone.product_description = new_upload_purchase_orders[po_number]['items'][item].product_description
              item_clone.qty_open = new_upload_purchase_orders[po_number]['items'][item].qty_open
              item_clone.qty_rcv = new_upload_purchase_orders[po_number]['items'][item].qty_rcv
              item_clone.ship_via = new_upload_purchase_orders[po_number]['items'][item].ship_via
              item_clone.unit_price = new_upload_purchase_orders[po_number]['items'][item].unit_price
              item_clone.uom = new_upload_purchase_orders[po_number]['items'][item].uom
              item_clone.po_status = new_upload_purchase_orders[po_number]['items'][item].po_status
              item_clone.drop_ship = new_upload_purchase_orders[po_number]['items'][item].drop_ship
              item_clone.ship_to_name = new_upload_purchase_orders[po_number]['items'][item].ship_to_name
              updates[`/purchase_orders/${po_number}/items/${item}`] = item_clone;
            }
          }else{

            // ['054706','055994','056037','056099']
            var eta_archive = {}
            var tracking_archive = {}
            var shipping_archive = {}
            var shared_notes_archive = {}
            var internal_notes_archive = {}

            for(var item_key in current_db_purchase_orders_clone[po_number].items){
              var item = current_db_purchase_orders_clone[po_number].items[item_key]
              if(item.eta){
                eta_archive[item.product] = item.eta 
              }
              if(item.tracking_number){
                tracking_archive[item.product] = item.tracking_number
              }
              if(item.shipping_company){
                shipping_archive[item.product] = item.shipping_company
              }
              if(item.shared_notes){
                shared_notes_archive[item.product] = item.shared_notes
              }
              if(item.internal_notes){
                internal_notes_archive[item.product] = item.internal_notes
              }
            }

            for(var item_key in new_upload_purchase_orders[po_number].items){
              var item = new_upload_purchase_orders[po_number].items[item_key]

              if(eta_archive[item.product]){
                new_upload_purchase_orders[po_number].items[item_key].eta = eta_archive[item.product]
              }

              if(tracking_archive[item.product]){
                new_upload_purchase_orders[po_number].items[item_key].tracking_number = tracking_archive[item.product]
              }

              if(shipping_archive[item.product]){
                new_upload_purchase_orders[po_number].items[item_key].shipping_company = shipping_archive[item.product]
              }

              if(shared_notes_archive[item.product]){
                new_upload_purchase_orders[po_number].items[item_key].shared_notes = shared_notes_archive[item.product]
              }

              if(internal_notes_archive[item.product]){
                new_upload_purchase_orders[po_number].items[item_key].internal_notes = internal_notes_archive[item.product]
              }                                
            }
            firebase.database().ref('purchase_orders').child(po_number).set(new_upload_purchase_orders[po_number]) // RESET THE PO BECAUSE ITEMS WERE ADDED OR ITEMS FELL OFF
            firebase.database().ref('purchase_orders').child(po_number).child('has_archive').set(true)
            firebase.database().ref('archive_of_reset_purchase_orders').push(current_db_purchase_orders_clone[po_number]) // ARCHIVE PO BECAUSE WE RESET IT
          }

        }else{
          firebase.database().ref('purchase_orders').child(po_number).set(new_upload_purchase_orders[po_number]) // ADD NEW PO TO DB
        }
      }

      firebase.database().ref().update(updates).then(function() {

      }).catch(function(error) {
        console.log("Error updating data:", error);
      });

      for(var po_number in current_db_purchase_orders_clone){ // iterate through current_db_purchase_orders_clone and mark those no longer in export as completed
        if(!new_upload_purchase_orders[po_number]){
          firebase.database().ref('purchase_orders').child(po_number).child('action_po_complete').set('Y')
          firebase.database().ref('purchase_orders').child(po_number).child('po_complete_date').set(Date.now())
        }
      }

      var date_now = Date.now()
      firebase.database().ref('last_purchase_order_file_upload').set(file_date)
      firebase.database().ref('last_purchase_order_file_pull').set(date_now)
      firebase.database().ref('purchase_order_upload_backup_before_update').child(date_now).set(current_db_purchase_orders_clone)

      self.filelist = []
      self.$store.state.isLoading = false
    },
  }
}
</script>


<style scoped>
  .dropzone{
    width: 100%;
    height: 50px;
    border: 2px dashed orange;
    border-radius: 4px;
  }
  .bg-white{
    background: white;
  }
  .bg-orange{
    background: orange;
  }

</style>
