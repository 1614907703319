<template>

  <v-app>

      <v-layout>

        <v-app-bar color="gray" density="compact" v-if="$route.path !== '/'">
          <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer" v-if="user && user !=='vendor' && $route.name !== 'vendor' && $route.name !== 'customer' && $route.name !== 'customer-ar' " ></v-app-bar-nav-icon>
          <v-app-bar-title><v-img :width="135" src="./assets/logo_vertical.svg"></v-img></v-app-bar-title>
          <template v-slot:append><span class="text-orange pointer text-caption" @click="logout()" v-if="user && user !=='vendor' && $route.name !== 'vendor' && $route.name !== 'customer' && $route.name !== 'customer-ar'">logout</span></template>
        </v-app-bar>

        <v-navigation-drawer disable-resize-watcher permanent v-model="drawer" color="gray" v-if="user && user !=='vendor' && $route.name !== 'vendor' && $route.name !== 'customer' && $route.name !== 'customer-ar' && $route.path !== '/'">

          <v-divider></v-divider>

          <v-list density="compact" nav>
            <template v-for="item in items">

              <v-list-item :prepend-icon="item.icon" :title="item.title" :value="item.title" v-if="!item.subitems"></v-list-item>

              <v-list-group v-if="item.subitems" :value="item.title" >
                <template v-slot:activator="{ props }">
                  <v-list-item v-bind="props" :prepend-icon="item.icon" :title="item.title" :active-class="'active'"></v-list-item>
                </template>
                <v-list-item v-for="(subitem, subindex) in item.subitems" :title="subitem.title" :value="subitem.title" @click="handleSubitemClick(subitem)"></v-list-item>
              </v-list-group>
            </template>
          </v-list>

          <span class="version-footer">
            {{$store.state.version}}
          </span>
        </v-navigation-drawer>

        <v-main>
          <v-container fluid class="scroll-container">
            <router-view/>
          </v-container>
        </v-main>

      </v-layout>

      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" :background-color="'#000'" :opacity="0.8" :color="'#ff7214'" :lock-scroll="true" :loader="'dots'" :blur="'0px'"></loading>
  </v-app>

</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'App',
  components: {Loading},
  data(){
    return{
      drawer: true,
      items: [
        {
          title: 'PURCHASING',
          icon: 'mdi-cart-variant',
          subitems: [
              { title: 'Purchasing Dashboard', link: 'purchasing-dashboard'},
              { title: 'Purchase Orders Master', link: 'purchase-orders-master'},
              { title: 'Recently Completed POs', link: 'completed-purchase-orders'},
              { title: 'ETA Master', link: 'eta-master'},
              { title: 'Vendors Master', link: 'vendors-master'},
          ]
        },
        {
          title: 'SALES',
          icon: 'mdi-account-multiple',
          subitems: [
              { title: 'Customer Links', link: 'customers'},
              { title: 'Backorder List', link: 'backorder-list'},
          ]
        },

        {
          title: 'SUPPORT',
          icon: 'mdi-face-agent',
          subitems: [
              { title: 'Support Dashboard', link: 'customer-support-dashboard'},
              { title: 'Proactive ETA Emails', link: 'proactive-etas'},
              { title: 'Non-Current ETAs', link: 'non-current-etas'}
          ]
        },

        {
          title: 'COLLECTIONS',
          icon: 'mdi-cash',
          subitems: [
              { title: 'Collections Candidates', link: 'collections-candidates'},
              { title: 'A/R Master', link: 'ar-master'}
          ]
        },

        {
          title: 'ADMINISTRATION',
          icon: 'mdi-cog',
          subitems: [
              { title: 'Data Imports', link: 'data-administration'},
              { title: 'Users', link: 'users-administration'},
              { title: 'Analytics', link: 'analytics-administration'},
          ]
        }
      ]
    }
  },
  watch: {

  },
  mounted(){
    var self = this
    firebase.auth().onAuthStateChanged(user => {
      if(user){
        self.loadDatabase(user.uid)
      }else{
        self.loadDatabase('vendor')
      }
    });
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      user: 'user'
    }),
  },
  watch: {

  },
  methods:{
    ...mapActions(['loadDatabase']),
    logout(){
      var self = this
      firebase.auth().signOut().then(function() { // force user logout if deactivated. see vueex store
        self.$router.push('/')
      })
    },
    handleSubitemClick(subitem) {
      this.$router.push({ name: subitem.link });
      // this.drawer = false;
    }
  }
}
</script>

<style>
@import './styles/easy-table.css';

body{
  overflow: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  overflow: none;
}

.link{
  color: var(--orange);
}

.version-footer{
  font-size: 8px;
  position: absolute;
  bottom: 2px;
  left: 12px;
}

.pointer{
  cursor: pointer;
}

.v-container{
  padding: 0;
}

.scroll-container {
  height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
}


</style>
