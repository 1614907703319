<template>
  <div class="vendor">

    <div v-if="showTable">

      <v-card style="padding:10px">
        <v-row>
          <v-col cols="6">
            <v-card-subtitle style="font-weight:bold">{{customer_info ? customer_info.customer_name : 'Loading....'}}</v-card-subtitle>
            <v-card-subtitle v-if="customer_info">Customer Number: {{customer_info.customer_number}}</v-card-subtitle>
            <v-card-subtitle v-if="customer_info">Account Executive: {{salesPersonInfo(customer_info.salesperson_code)}}</v-card-subtitle>
            <!-- <v-card-subtitle v-if="customer_info">Account Specialist: {{accountSpecialistInfo(customer_info.account_specialist)}}</v-card-subtitle> -->
            <v-card-subtitle v-if="customer_info">Master AR Account: {{customer_info.master_ar_account}}</v-card-subtitle>
          </v-col>
          <v-col cols="6" v-if="customer_info && customer_info.child_ar_accounts">
            <v-card-subtitle v-if="customer_info" style="font-weight:bold">Child AR Accounts {{customer_info.child_ar_accounts ? '' : 'none'}}</v-card-subtitle>
            <v-card-subtitle v-if="customer_info" v-for="a in customer_info.child_ar_accounts" style="margin-left:25px;font-style:italic;font-size:10px;line-height:120%">{{db.customers['customer_'+a].customer_name}} ({{a}})</v-card-subtitle>
          </v-col>
        </v-row>
      </v-card>

      <v-card style="margin-top:10px;padding:10px" v-if="user !== 'vendor' && customer_info.has_dedicated_eta_manager === 'Yes, Account Specialist'">
        <div style="color:gray;font-size:12px;font-weight:bold">Communication ETA Status: <span :class="statusClass(customer_eta_status_computed)">{{ customer_eta_status_computed }}</span>   </div>
        <v-btn style="background:#ff7214;color:white;width:150px;height:20px;font-size:10px;margin:5px 0px" @click="etaUpdateProvided()">SEND ETA UPDATE</v-btn>
        <div style="font-size:10px;font-style:italic;color:gray">Last ETA Update: {{customer_last_eta_status_update}}</div>
      </v-card>

      <div style="margin-top:10px">

        <div style="margin-bottom:10px">
          <v-row>
            <v-col cols="6">
              <v-text-field label="Search Backorders..." variant="solo" density="comfortable" hide-details="true" @input="debouncedSearch($event)" style="width:350px"></v-text-field>
            </v-col>
            <v-col cols="6" style="display: flex; flex-direction: column; justify-content: flex-end; align-items: flex-end;">

              <div style="font-size:12px;font-style:italic;">Last Imported Backorders File Date: {{lastUploadDate}}</div>

              <div style="font-size:12px;font-style:italic;line-height:100%;" v-if="user !== 'vendor'">* = Unlinked PO</div>

              <div style="font-size:12px;font-style:italic;line-height:100%;background:#ff7214;color:white;padding:2px;font-weight:bold" v-if="user !== 'vendor'">Orange header columns are only visible to Riverhawk users</div>
            </v-col>
          </v-row>
        </div>

        <Vue3EasyDataTable
          :headers="headers"
          :items="customer_backorders"
          :search-value="searchValue"
          :sort-by="sortBy"
          :sort-type="sortType"
          :hide-footer="false"
          :rows-per-page="250"
          :rows-items="[250, 500, 1000, 5000]"
          :hide-rows-per-page="true"
          :table-height="table_height"
          :empty-message="data_message"
        >

          <template #header-critical_item="header">
            <div class="riverhawk-header">
              <div class="riverhawk-header-text">{{header.text}}</div>
            </div>
          </template>

          <template #header-order_writer_name="header">
            <div class="riverhawk-header">
              <div class="riverhawk-header-text">{{header.text}}</div>
            </div>
          </template>

          <template #header-linked_po_number="header">
            <div class="riverhawk-header">
              <div class="riverhawk-header-text">{{header.text}}</div>
            </div>
          </template>

          <template #header-internal_notes="header">
            <div class="riverhawk-header">
              <div class="riverhawk-header-text">{{header.text}}</div>
            </div>
          </template>

          <template #header-vendor="header">
            <div class="riverhawk-header">
              <div class="riverhawk-header-text">{{header.text}}</div>
            </div>
          </template>

          <template #item-critical_item="item">
            <div class="critical_btn" @click="flagCritical(item.backorder_key)" v-if="!item.critical_item"></div>
            <div class="critical_btn_active" @click="unflagCritical(item.backorder_key)" v-if="item.critical_item"></div>
          </template>

          <template #item-linked_po_number="item">
            <span v-if="item.po_for_detail" style='color:#ff7214;cursor:pointer;font-weight:bold' @click="openPurchaseOrderDetailPopup(item.po_for_detail)">{{item.linked_po_number}}</span>
            <span v-if="!item.po_for_detail">{{item.linked_po_number}}</span>
          </template>

          <template #item-vendor="item">
            <router-link v-if="item.vendor_page" :to="{ name: 'vendor', params: {vendorpageid: item.vendor_page}}" target="_blank" style="color:#ff7214;font-weight:bold">{{item.vendor}}</router-link>
            <span v-if="!item.vendor_page">{{item.vendor}}</span>
          </template>

          <template #item-eta="item">

            <div v-if="item.non_current_eta && user !== 'vendor' && item.approx_eta_set" style="background:red;color:white;font-weight:bold;padding:4px;text-decoration:underline">{{item.eta}}</div>
            <div v-if="item.non_current_eta && user !== 'vendor' && !item.approx_eta_set" style="background:red;color:white;font-weight:bold;padding:4px;">{{item.eta}}</div>

            <div v-if="!item.non_current_eta || user === 'vendor'" style="padding:4px">
              <div v-if="user === 'vendor'">{{item.eta}}</div>
              <div v-if="user !== 'vendor' && item.approx_eta_set" style="text-decoration:underline">{{item.eta}}</div>
              <div v-if="user !== 'vendor' && !item.approx_eta_set">{{item.eta}}</div>
            </div>
          </template>

          <template #item-ordered_by_name="item">
            <div>{{item.ordered_by_name}}</div>
            <div style="font-style:italic;font-size:10px">{{item.email_address_of_ordered_by}}</div>
          </template>

          <template #item-internal_notes="item">
            <div v-for="n in item.internal_notes" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;min-width:100px">
              <div style="font-style:italic">{{n.note}}</div>
              <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}} {{n.submitted_by_email}}</div>
            </div>
            <v-btn v-if="user !== 'vendor'" style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px" @click="backorderNote('internal',item.id)">ADD NOTE</v-btn>
          </template>

          <template #item-inquiries="item">
            <div v-for="n in item.inquiries" style="border-bottom:1px dashed gray;margin-top:5px;margin-bottom:5px;line-height:100%;min-width:100px">
              <div style="font-style:italic">{{n.note}}</div>
              <div style="font-size:8px;line-height:100%;color:gray;">submitted {{formatDate(n.submitted_date)}} by {{n.submitted_by_name}} {{n.submitted_by_email}}</div>
            </div>
            <v-btn v-if="user !== 'vendor'" style="background:white;color:#ff7214;height:15px;width:50px;font-size:8px;margin:5px 0px" @click="backorderNote('shared',item.id)">ADD NOTE</v-btn>
          </template>

          <template #item-inquire="item">
            <v-btn style="background:#ff7214;color:white;height:15px;width:50px;font-size:10px;margin:5px 0px" @click="backorder_item_inquiry_key = item.backorder_key">INQUIRE</v-btn>
          </template>

        </Vue3EasyDataTable>

        <v-row>
          <v-col cols="6">
            <v-btn style="background:#ff7214;color:white;height:25px;width:55px;font-size:12px;margin:5px 0px;" @click="exportTable()">EXPORT</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn style="background:#ff7214;color:white;height:25px;font-size:12px;margin:5px 0px 0px 10px;float:right" @click="shareFeedback()">SHARE YOUR FEEDBACK</v-btn>          
            <v-btn style="background:#ff7214;color:white;height:25px;font-size:12px;margin:5px 0px;float:right" @click="faq_popup = true">FAQ</v-btn>
          </v-col>        
        </v-row>

      <v-card style="margin-top:20px">
        <v-card-text>Recently Completed Backorders</v-card-text>
        <v-row style="padding:0px 20px 20px 20px">
          <Vue3EasyDataTable
            :headers="recently_completed_backorders_headers"
            :items="customer_recently_completed_backorders"
            :hide-footer="true"
            :rows-per-page="25000"
            :hide-rows-per-page="true"
            :empty-message="'No recently completed backorders'"
            style="width: 100%;"
          >
          </Vue3EasyDataTable>
        </v-row>
      </v-card>
        
      </div>

    </div>
    <span class="version-footer">
      {{$store.state.version}}
    </span>
    <FaqPopUp v-if="faq_popup" @close="faq_popup = false" />
    <FeedbackPopUp v-if="feedback_popup" :customer='customer_for_component' @close="feedback_popup = false" />
    <PurchaseOrderDetailPopUp v-if="po_for_component" :po='po_for_component' @close="closePurchaseOrderDetailPopup()" />
    <BackorderInquiryPopUp v-if="backorder_item_inquiry_key" @close="backorder_item_inquiry_key = false" :backorder='backorder_item_inquiry_key'/>
    <BackorderNotePopUp v-if="backorder_note_popup" @close="backorder_note_popup = false" :backorderkey="backorder_key_for_component" :type="backorder_note_type"/>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment'
import _ from 'lodash'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { debounce } from 'lodash';
import BackorderInquiryPopUp from '@/components/BackorderInquiryPopUp.vue'
import PurchaseOrderDetailPopUp from '@/components/PurchaseOrderDetailPopUp.vue'
import FeedbackPopUp from '@/components/FeedbackPopUp.vue'
import BackorderNotePopUp from '@/components/BackorderNotePopUp.vue'
import FaqPopUp from '@/components/FaqPopUp.vue'

import XLSX from 'xlsx'

export default {
  name: 'VendorView',
  components: {Vue3EasyDataTable,BackorderInquiryPopUp,PurchaseOrderDetailPopUp,BackorderNotePopUp,FeedbackPopUp,FaqPopUp},
  data(){
    return {
      feedback_popup: false,
      backorder_note_popup: false,
      backorder_note_type: null,
      customer_page_id: this.$route.params['customerpageid'],
      customer_info: null,
      table_height: 300,
      searchValue: '',
      sortBy: 'customer_name',
      sortType: 'desc',
      backorder_item_inquiry_key: false,
      showTable: false,
      po_for_component: false,
      faq_popup: false,
      data_message: 'Great news - no backorders!'
    }
  },
  created() {
    this.sizeTable()
    window.addEventListener("resize", this.sizeTable);
  },
  destroyed() {
    window.removeEventListener("resize", this.sizeTable);
  },
  beforeMount(){
    var self = this

    self.$store.state.isLoading = true

    firebase.database().ref('customers').child('customer_'+self.customer_id).once('value', function(customer_snapshot) {
      self.customer_info = customer_snapshot.val()
      self.customer_info.id = customer_snapshot.key

      firebase.database().ref('analytics').push({
        page: 'customer',
        customer_name: self.customer_info.customer_name,
        customer_id: self.customer_info.id,
        type: localStorage.getItem('isNestUser') === 'true' ? 'internal' : 'external',
        date: Date.now(),
        user: localStorage.getItem('isNestUser') === 'true' ? localStorage.getItem('nestUser') : 'customer',
        app_version: self.$store.state.version
      })
    })
    setTimeout(() => {
      self.showTable = true;
      self.$store.state.isLoading = false
    }, 2000);
  },
  computed: {
    ...mapGetters(['user','db','po_confirmation_status','item_eta_status']),
    lastUploadDate(){
      return Moment(this.db.last_backorder_file_upload).format('MM/DD/YY hh:mma')
    },
    customer_eta_status_computed(){
      var date_now = Moment()
      var last_eta_update_date = this.db.customers['customer_'+this.customer_id].last_eta_update ? Moment(this.db.customers['customer_'+this.customer_id].last_eta_update) : false
      var provide_eta_updates_every_x_days = parseFloat(this.db.customers['customer_'+this.customer_id].send_eta_update_every_x_days)

      if(last_eta_update_date){
        var days_difference = date_now.diff(last_eta_update_date, 'days')
        if (days_difference >= provide_eta_updates_every_x_days) {
          return 'Due Now';
        } else if (provide_eta_updates_every_x_days - days_difference <= 3) {
          return 'Due Soon';
        } else if (days_difference < provide_eta_updates_every_x_days - 3) {
          return 'Complete';
        }
      }else{
        return 'Due Now'
      }
    },
    customer_last_eta_status_update(){
      return this.db.customers['customer_'+this.customer_id].last_eta_update ? Moment(this.db.customers['customer_'+this.customer_id].last_eta_update).format('MM/DD/YY hh:mma') : 'none'
    },
    customer_id(){
      return this.customer_page_id.split('937')[0]
    },
    headers(){

      if(this.user !== 'vendor'){
        return [
          // DON'T FORGET TO MAKE EDITS TO THE EXCEL EXPORT IF YOU UPDATE HEADERS
          // DON'T FORGET TO MAKE EDITS TO THE EXCEL EXPORT IF YOU UPDATE HEADERS
          { text: "Critical Item", value: "critical_item", sortable: true },
          { text: "Customer Name", value: "customer_name", sortable: true },
          { text: "Riverhawk Sales Order Number", value: "order_number", sortable: true },
          { text: "Order Date", value: "order_date", sortable: true },
          { text: "Riverhawk Product Number", value: "product_number", sortable: true },
          { text: "Description", value: "description_line_one", sortable: true },
          { text: "Customer PO Number", value: "customer_po", sortable: true },
          
          { text: "Ship To City", value: "ship_to_city", sortable: true },

          { text: "Shipping Company", value: "shipping_company", sortable: true },
          { text: "Tracking Number", value: "tracking_number", sortable: true },

          { text: "Ordered By", value: "ordered_by_name", sortable: true },
          { text: "Qty Backordered", value: "quantity_backordered", sortable: true },
          { text: "UOM", value: "unit_of_measure", sortable: true },
          { text: "Order Writer", value: "order_writer_name", sortable: true },
          { text: "Dropship?", value: "dropship", sortable: true },
          { text: "Linked PO Number", value: "linked_po_number", sortable: true },
          { text: "Vendor", value: "vendor", sortable: true },
          { text: "ETA", value: "eta", sortable: true },

          { text: "Internal Notes", value: "internal_notes", sortable: false },

          { text: "Shared Notes", value: "inquiries", sortable: false },
          { text: "", value: "inquire", sortable: false }
        ]
      }
      else{
        return [
          // DON'T FORGET TO MAKE EDITS TO THE EXCEL EXPORT IF YOU UPDATE HEADERS
          // DON'T FORGET TO MAKE EDITS TO THE EXCEL EXPORT IF YOU UPDATE HEADERS
          { text: "Customer Name", value: "customer_name", sortable: true },
          { text: "Riverhawk Sales Order Number", value: "order_number", sortable: true },
          { text: "Order Date", value: "order_date", sortable: true },
          { text: "Riverhawk Product Number", value: "product_number", sortable: true },
          { text: "Description", value: "description_line_one", sortable: true },
          { text: "Customer PO Number", value: "customer_po", sortable: true },
          { text: "Ship To City", value: "ship_to_city", sortable: true },
          { text: "Shipping Company", value: "shipping_company", sortable: true },
          { text: "Tracking Number", value: "tracking_number", sortable: true },          
          { text: "Ordered By", value: "ordered_by_name", sortable: true },
          { text: "Qty Backordered", value: "quantity_backordered", sortable: true },
          { text: "UOM", value: "unit_of_measure", sortable: true },
          { text: "Dropship?", value: "dropship", sortable: true },
          { text: "ETA", value: "eta", sortable: true },
          { text: "Shared Notes", value: "inquiries", sortable: true },
          { text: "", value: "inquire", sortable: false }
        ]
      }
    },
    recently_completed_backorders_headers(){
      if(this.user !== 'vendor'){
        return [
          { text: "Customer Name", value: "customer_name", sortable: true },
          { text: "Riverhawk Sales Order Number", value: "order_number", sortable: true },
          { text: "Order Date", value: "order_date", sortable: true },
          { text: "Riverhawk Product Number", value: "product_number", sortable: true },
          { text: "Description", value: "description_line_one", sortable: true },
          { text: "Customer PO Number", value: "customer_po", sortable: true },
          { text: "Ship To City", value: "ship_to_city", sortable: true },
          { text: "Shipping Company", value: "shipping_company", sortable: true },
          { text: "Tracking Number", value: "tracking_number", sortable: true },          
          { text: "Ordered By", value: "ordered_by_name", sortable: true },
          { text: "UOM", value: "unit_of_measure", sortable: true },
          { text: "Order Writer", value: "order_writer_name", sortable: true },
          { text: "Dropship?", value: "dropship", sortable: true },
          { text: "Linked PO Number", value: "linked_po_number", sortable: true }
        ]
      }
      else{
        return [
          { text: "Customer Name", value: "customer_name", sortable: true },
          { text: "Riverhawk Sales Order Number", value: "order_number", sortable: true },
          { text: "Order Date", value: "order_date", sortable: true },
          { text: "Riverhawk Product Number", value: "product_number", sortable: true },
          { text: "Description", value: "description_line_one", sortable: true },
          { text: "Customer PO Number", value: "customer_po", sortable: true },
          { text: "Ship To City", value: "ship_to_city", sortable: true },
          { text: "Shipping Company", value: "shipping_company", sortable: true },
          { text: "Tracking Number", value: "tracking_number", sortable: true },          
          { text: "Ordered By", value: "ordered_by_name", sortable: true },
          { text: "UOM", value: "unit_of_measure", sortable: true },
          { text: "Dropship?", value: "dropship", sortable: true }
        ]
      }
    },
    customer_recently_completed_backorders(){
      var customer_child_ar_accounts = this.customer_info && this.customer_info.child_ar_accounts ? this.customer_info.child_ar_accounts : []
      var backorders_db_clone = this.db.recently_completed_backorders ? JSON.parse(JSON.stringify(this.db.recently_completed_backorders)) : {}
      var backorders = []
      
      for(var b in backorders_db_clone){
        var backorder = backorders_db_clone[b]
        backorder.id = b
        backorder.missing_linked_po_number = !backorder.linked_po_number || backorder.linked_po_number.trim() === ""
        if(customer_child_ar_accounts.indexOf(backorder.customer_number) !== -1 || backorder.customer_number === this.customer_info.customer_number){
          backorder.dropship = backorder.line_item_warehouse === '09' ? 'YES' : 'NO'
          backorders.push(backorder)
        }
      }
      return backorders
    },
    customer_backorders(){
      var customer_child_ar_accounts = this.customer_info && this.customer_info.child_ar_accounts ? this.customer_info.child_ar_accounts : []
      var backorders_db_clone = this.db.backorders ? JSON.parse(JSON.stringify(this.db.backorders)) : {}
      var backorders = []

      for(var b in backorders_db_clone){
        var backorder = backorders_db_clone[b]
        backorder.id = b
        if(!backorder.completed && (customer_child_ar_accounts.indexOf(backorder.customer_number) !== -1 || backorder.customer_number === this.customer_info.customer_number)){
          backorder.dropship = backorder.line_item_warehouse === '09' ? 'YES' : 'NO'
          backorder.critical_item = backorder.critical_item ? true : false
          if(this.db.purchase_orders[backorder.linked_po_number] ){

            backorder.po_for_detail = backorder.linked_po_number
            backorder.vendor = this.db.purchase_orders[backorder.linked_po_number].vendor_name
            backorder.vendor_number = this.db.purchase_orders[backorder.linked_po_number].vendor_number
            backorder.vendor_page = this.db.vendors['vendor_'+backorder.vendor_number].page
            backorder.eta = 'Item Not Found In Nest PO'

            backorder.non_current_eta = false

            backorder.has_linked_po = true
            for(var item_key in this.db.purchase_orders[backorder.linked_po_number].items){

              if(this.db.purchase_orders[backorder.linked_po_number].items[item_key].product === backorder.product_number){
                backorder.eta = this.db.purchase_orders[backorder.linked_po_number].items[item_key].eta
                
                if(this.db.purchase_orders[backorder.linked_po_number].items[item_key].line_complete === 'Y'){
                  backorder.eta = 'Complete'
                }

                if(this.db.purchase_orders[backorder.linked_po_number].items[item_key].approx_eta_set){
                  backorder.approx_eta_set = true
                }

                if(backorder.eta === 'UTP'){
                  backorder.eta = 'Vendor Unable To Provide Date'
                }

                if(backorder.eta === 'TBD'){
                  backorder.eta = 'ETA TBD'
                }

                if(backorder.eta === ''){
                  backorder.eta = this.item_eta_status(this.db.purchase_orders[backorder.linked_po_number],item_key)
                }
              }

            }

          }else{
            if(backorder.missing_linked_po_number){
              backorder.has_linked_po = false
              var potential_etas = []
              for(var po in this.db.purchase_orders){

                if (/[a-zA-Z]/.test(po)) { //make sure PO is not a special order with a letter
                  continue;
                }

                for(var item in this.db.purchase_orders[po].items){
                  if(this.db.purchase_orders[po].items[item].product === backorder.product_number  && this.db.purchase_orders[po].items[item].line_complete !== 'Y'){
                    potential_etas.push(this.db.purchase_orders[po].items[item].eta + '|' + this.db.purchase_orders[po].po_number + '|'  + this.db.purchase_orders[po].vendor_name + '|'  + this.db.purchase_orders[po].vendor_number + '|'  + this.db.vendors['vendor_'+this.db.purchase_orders[po].vendor_number].page )
                  }
                }
              }

              var hasEmptyString = false;
              var hasUTPValue = false;
              var hasTBDValue = false;
              var earliestDate = null;
              var po;
              var vendor;
              var vendor_number;
              var vendor_page;
              var po_for_detail;

              for (var i = 0; i < potential_etas.length; i++) {
                const eta = potential_etas[i].split('|')[0];
                const p = potential_etas[i].split('|')[1];
                const v = potential_etas[i].split('|')[2];
                const v_number = potential_etas[i].split('|')[3];
                const v_page = potential_etas[i].split('|')[4];

                if (eta === '') {
                  hasEmptyString = true;
                  po = p
                  vendor = v
                  vendor_number = v_number
                  vendor_page = v_page
                  po_for_detail = po
                  continue;
                }

                if (eta === 'UTP') {
                  hasUTPValue = true;
                  po = p
                  vendor = v
                  vendor_number = v_number
                  vendor_page = v_page
                  po_for_detail = po
                  continue;
                }

                if (eta === 'TBD') {
                  hasTBDValue = true;
                  po = p
                  vendor = v
                  vendor_number = v_number
                  vendor_page = v_page
                  po_for_detail = po
                  continue;
                }

                const date = Moment(eta, 'M/DD/YY');

                if (earliestDate === null || date.isBefore(earliestDate, 'day')) {
                  earliestDate = date;
                  po = p
                  vendor = v
                  vendor_number = v_number
                  vendor_page = v_page
                  po_for_detail = po
                }
              }

              if (earliestDate === null) {
                if (hasUTPValue) {
                  backorder.eta = 'Vendor Unable To Provide Date';
                  backorder.linked_po_number = po + ' *'
                  backorder.vendor = vendor + ' *'

                  backorder.vendor_number = vendor_number
                  backorder.vendor_page = vendor_page
                  backorder.po_for_detail = po_for_detail
                } else if (hasTBDValue) {
                  backorder.eta = 'ETA TBD';
                  backorder.linked_po_number = po + ' *'
                  backorder.vendor = vendor + ' *'

                  backorder.vendor_number = vendor_number
                  backorder.vendor_page = vendor_page
                  backorder.po_for_detail = po_for_detail
                } else if (hasEmptyString) {
                  backorder.eta = 'Pending ETA From Vendor';
                  backorder.linked_po_number = po + ' *'
                  backorder.vendor = vendor + ' *'

                  backorder.vendor_number = vendor_number
                  backorder.vendor_page = vendor_page
                  backorder.po_for_detail = po_for_detail
                } else {
                  backorder.eta = this.user !== 'vendor' ? 'No PO Found' : 'PO Conf Pending'
                  backorder.linked_po_number = this.user !== 'vendor' ? 'No PO Found' : 'PO Conf Pending'
                  backorder.vendor = this.user !== 'vendor' ? 'No PO Found' : 'PO Conf Pending'

                  backorder.vendor_number = false
                  backorder.vendor_page = false
                  backorder.po_for_detail = false
                }
                backorder.non_current_eta = true
              } else {
                  backorder.eta = earliestDate.format('M/DD/YY');
                  backorder.linked_po_number = po + ' *'
                  backorder.vendor = vendor + ' *'

                  backorder.vendor_number = vendor_number
                  backorder.vendor_page = vendor_page
                  backorder.po_for_detail = po_for_detail
              }

            }
            if(!backorder.missing_linked_po_number){
              backorder.eta = this.user !== 'vendor' ? 'Linked PO not open in Nest' : 'PO Conf Pending'
              backorder.vendor = 'Linked PO not open in Nest'
            }
          }

          if (Moment(backorder.eta, 'M/DD/YY').valueOf() < Moment().startOf('day').valueOf()) {
            backorder.eta = '(' + backorder.eta + ') Past Due, Update Pending';
            backorder.non_current_eta = true
          }

          if(backorder.eta == 'PO Conf Pending' || backorder.eta == 'Vendor Unable To Provide Date' || backorder.eta == 'Not Requested' || backorder.eta == 'PO Disputed'){
            backorder.non_current_eta = true
          }

          if(!backorder.has_linked_po && this.user !== 'vendor' && backorder.eta !== 'Linked PO not open in Nest'){
            backorder.eta = backorder.eta + ' *'
          }

          backorders.push(backorder)
        }
      }
      return backorders
    }
  },
  methods: {
    statusClass(status) {
      switch (status) {
        case 'Complete':
          return 'status-complete';
        case 'Due Soon':
          return 'status-due-soon';
        case 'Due Now':
          return 'status-due-now';
        default:
          return '';
      }
    },    
    etaUpdateProvided(){
      var self = this
      var customer = self.customer_info

      if(customer.eta_contact_emails.length === 0){
        alert('No customer email provided. Please add to Inform.')
      }
      
      var msg = 'Click \'OK\' to provide an ETA update for ' + customer.customer_name
      var eta_sent = confirm(msg)

      if(eta_sent){
        var timestamp = Date.now()
        firebase.database().ref('customers').child(customer.id).child('eta_updates').push({timestamp: timestamp, sent_by: self.user})
        firebase.database().ref('customers').child(customer.id).child('last_eta_update').set(timestamp)

        var to = customer.eta_contact_emails
        var page = 'https://riverhawknest.com/customer/'+customer.page
        window.location.href = 'mailto:' + customer.eta_contact_emails+'?subject=Riverhawk ETA Updates&body=Dear Valued Customer,%0D%0A%0D%0APlease follow this link to view your backorder ETAs:%0D%0A%0D%0A'+page+'%0D%0A%0D%0AIf you have any questions or concerns regarding your backorders, please leave a note using the Inquire button. I would also be happy to advise on available substitutes.'+'%0D%0A%0D%0AThank you,%0D%0A%0D%0A'+this.db.users[this.user].first_name + ' '+this.db.users[this.user].last_name
      }
    },    
    shareFeedback(){
      this.customer_for_component = this.customer_info.customer_name
      this.feedback_popup = true
    },
    backorderNote(type,id){
      this.backorder_note_type = type
      this.backorder_key_for_component = id
      this.backorder_note_popup = true
    },
    salesPersonInfo(code){
      var info = null
      for(var u in this.db.users){
        if(this.db.users[u].initials === code){
          info = this.db.users[u]
          break;
        }
      }
      return info ? info.first_name + ' ' + info.last_name + ' ' + info.email + '' : ''
    },
    accountSpecialistInfo(code){
      var info = null
      for(var u in this.db.users){
        if(this.db.users[u].initials === code){
          info = this.db.users[u]
          break;
        }
      }
      return info ? info.first_name + ' ' + info.last_name + ' ' + info.email + '' : ''
    },
    exportTable(){

      var self = this
      var items = self.customer_backorders
      var export_arr = []

      for(var i = 0; i < items.length; i++){
        var row;
        if(self.user !== 'vendor'){
          row = {
            'Customer Name' : items[i].customer_name,
            'Riverhawk Sales Order Number' : items[i].order_number,
            'Order Date' : items[i].order_date,
            'Riverhawk Product Number' : items[i].product_number,
            'Description' : items[i].description_line_one,
            'Customer PO Number' : items[i].customer_po,
            'Ship To City' : items[i].ship_to_city,
            'Ordered By' : items[i].ordered_by_name,
            'Qty Backordered' : items[i].quantity_backordered,
            'UOM' : items[i].unit_of_measure,
            'Order Writer' : items[i].order_writer_name,
            'Dropship?' : items[i].dropship,
            'Linked PO Number' : items[i].linked_po_number,
            'Vendor' : items[i].vendor,
            'ETA' : items[i].eta,
            'Internal Notes' : '',
            'Shared Notes' : '',
          }

          for(var n in items[i].internal_notes){
            var note_detail = items[i].internal_notes[n]
            row['Internal Notes'] += note_detail.note + ' (' + note_detail.submitted_by_name + ' ' + note_detail.submitted_by_email +  ' on ' + self.formatDate(note_detail.submitted_date) + '), '
          }

        }else{
          row = {
            'Customer Name' : items[i].customer_name,
            'Riverhawk Sales Order Number' : items[i].order_number,
            'Order Date' : items[i].order_date,
            'Riverhawk Product Number' : items[i].product_number,
            'Description' : items[i].description_line_one,
            'Customer PO Number' : items[i].customer_po,
            'Ordered By' : items[i].ordered_by_name,
            'Qty Backordered' : items[i].quantity_backordered,
            'UOM' : items[i].unit_of_measure,
            'Dropship?' : items[i].dropship,
            'ETA' : items[i].eta,
            'Shared Notes' : '',
          }
        }

        for(var n in items[i].inquiries){
          var note_detail = items[i].inquiries[n]
          row['Shared Notes'] += note_detail.note + ' (' + note_detail.submitted_by_name + ' ' + note_detail.submitted_by_email +  ' on ' + self.formatDate(note_detail.submitted_date) + '), '
        }

        export_arr.push(row)

      }

      var worksheet = XLSX.utils.json_to_sheet(export_arr)
      worksheet['!cols'] = Array(self.headers.length).fill({wch:30});
      var workbook = XLSX.utils.book_new()
      var export_date = Date.now()
      XLSX.utils.book_append_sheet(workbook, worksheet)
      XLSX.writeFile(workbook, self.customer_info.customer_name + '_' + export_date + '.xlsx')
    },
    sizeTable(){
      var wh = window.innerHeight;
      this.table_height = wh - 445
    },
    debouncedSearch: debounce(function(event) {
      this.searchValue = event.target._value.replace(/\*/g, "");
    }, 500),
    formatDate(date){
      return Moment(date).format('MM/DD/YY')
    },
    flagCritical(item){
      var self = this
      var confirm_flag = confirm('Are you sure you want to flag ' + this.db.backorders[item].product_number + '?')
      if(confirm_flag){
        firebase.database().ref('backorders').child(item).child('critical_history').push({'set_to': true, 'set_by': (self.user !== 'vendor' ? self.user : 'customer'), 'set_date': Date.now()})
        firebase.database().ref('backorders').child(item).child('critical_item').set(true)
      }
    },
    unflagCritical(item){
      var self = this
      var confirm_unflag = confirm('Are you sure you want to unflag ' + this.db.backorders[item].product_number + '?')
      if(confirm_unflag){
        firebase.database().ref('backorders').child(item).child('critical_history').push({'set_to': false, 'set_by': (self.user !== 'vendor' ? self.user : 'customer'), 'set_date': Date.now()})
        firebase.database().ref('backorders').child(item).child('critical_item').set(false)
      }
    },
    openPurchaseOrderDetailPopup(po){
      this.po_for_component = po
    },
    closePurchaseOrderDetailPopup(){
      this.po_for_component = false
    },
  }
}
</script>

<style scoped>

  .vendor{
    margin: 20px;
  }

  table{
    font-size: 12px;
  }
  th {
    text-align: left;
    padding-right: 20px;
  }
  td {
    text-align: left;
    padding-right: 20px;
  }

  .table-container{
    padding: 15px;
  }

  .table-title{
    padding-top: 15px;
  }

  .critical_btn{
    border: 1px solid #ff7214;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .critical_btn_active{
    background: #ff7214;
    border: 1px solid #ff7214;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 16px;
  }

  .critical_btn_active::after{
      content: "✔";
  }

  .riverhawk-header{
    background: #ff7214;
    height: 40px;
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
  }

  .riverhawk-header-text{
    color: white;
    font-weight: bold;
    padding: 5px;
  }

.status-complete {
    color: green;
    width: 70px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    margin-left: 5px;
}

.status-due-soon {
    color: #FFA500;
    width: 70px;
    text-align: center;
    font-weight: bold;
    border-radius: 2px;
    font-size: 12px;
    margin-left: 5px;
}

.status-due-now {
    color: red;
    width: 70px;
    text-align: center;
    font-weight: bold;
    border-radius: 2px;
    font-size: 12px;
    margin-left: 5px;
}
</style>
